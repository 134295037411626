export const Home = process.env.REACT_APP_METALIT_URL
export const Course = Home + `/course`
export const About = Home + `/about`
export const Help = Home + `/help`
export const Contact = Home + `/contact`
export const Term_Conditions = Home + `/term-conditions`
export const Privacy_Policy = Home + `/privacy-policy`
export const Upload_CV = Home + `/api/upload-cv/`
export const API_Course = Home + `/api/course/`
export const Login = Home + `/accounts/login/`
export const Daftar = Home + `/accounts/signup/`